@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Poppins;
  }

  /* table th:nth-child(1) { */

  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  /* -webkit-border-radius: 10px 0px 0px 0px; */

  /* Firefox 1-3.6 */
  /* -moz-border-radius: 10px 0px 0px 0px; */

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  /* border-radius: 10px 0px 0px 0px; */
  /* } */

  /* table th:nth-last-child(1) { */
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  /* -webkit-border-radius: 0px 10px 0px 0px; */

  /* Firefox 1-3.6 */
  /* -moz-border-radius: 0px 10px 0px 0px; */

  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  /* border-radius: 0px 10px 0px 0px; */
  /* } */

  table tbody tr td {
    padding: 10px;
  }

  table tbody tr {
    border-bottom: 2px;
    border-color: black;
  }

  table thead tr th {
    padding: 8px;
  }
}

@layer components {
  .active {
    @apply text-orange-400 font-bold;
  }

  .active span {
    @apply text-black;
  }

  .row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-grow max-w-full;
  }

  .card {
    @apply relative w-full border-2 rounded-lg bg-white;
  }

  .card-header {
    @apply p-3 border-b-2;
  }

  .card-body {
    @apply flex flex-shrink flex-grow flex-col gap-2 p-3;
  }

  .card-footer {
    @apply flex p-3 border-t-2;
  }

  .box-waiting {
    @apply h-[25px] w-[25px] bg-[#976D20] rounded-md;
  }

  .box-pink {
    @apply h-[25px] w-[25px] bg-[#CD2073] rounded-md;
  }

  .mini-sidebar {
    @apply w-16 block !important;
  }

  .mini-sidebar > span {
    @apply hidden;
  }

  .mini-sidebar > div ul {
    @apply w-16;
  }

  .mini-sidebar > div > ul > li {
    @apply text-center;
  }

  .mini-sidebar > div > ul > li a {
    @apply pl-0;
  }

  .mini-sidebar > div > ul > li span {
    @apply hidden;
  }

  .btn {
    @apply rounded-lg py-1 px-2 border text-xs font-semibold items-center;
  }

  .btn-grey {
    @apply bg-[#534949] text-white;
  }

  .btn-otw {
    @apply bg-orange-200 text-orange-900  hover:bg-orange-300 hover:text-white;
  }

  .btn-selesai {
    @apply bg-green-200 text-green-900 hover:bg-green-900 hover:text-white;
  }
  /* ... */
}
